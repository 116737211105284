import load from '@/utils/load-promise'

export default (ctx, inject) => {
  const version = 'v2'

  const mercadoPago = {
    instance: null,
    loaded: false,
    init: () => {
      mercadoPago.loaded = true
      let limit = 30

      const interval = setInterval(() => {
        if (window.MercadoPago) {
          clearInterval(interval)

          // const sandbox = 'false' === 'true'

          mercadoPago.instance = new window.MercadoPago(
            'APP_USR-bab10532-e3f8-404a-a0f2-f6c2f8b4cf28',
            {
              locale: 'en-US',
              advancedFraudPrevention: true,
            }
          )

          load
            .js(
              `https://www.mercadopago.com/${version}/security.js`,
              'mercadopago-security-src',
              'defer'
            )
            .then(() => {})
            .catch(() => {})
        } else {
          limit--
        }

        if (limit === 0) {
          clearInterval(interval)
        }
      }, 200)
    },
    load: () => {
      if (!mercadoPago.loaded) {
        load
          .js(
            `https://sdk.mercadopago.com/js/${version}/`,
            'mercadopago-src',
            'defer'
          )
          .then(() => mercadoPago.init())
          .catch(() => {})
      }
    },
  }

  inject('mercadoPago', mercadoPago)
}
